import * as React from "react"
import Layout from "../components/layout"
import { Container, Section, Text, SuperHeading } from "../components/ui"
import * as styles from "../pagesCss/index.css"

export default function Homepage() {
  return (
    <Layout>
      <Section>
        <Container>
          <SuperHeading className={styles.aboutHeroHeader}>
            We build sustainable software companies that address the unique
            needs of small-businesses.
          </SuperHeading>

          <Text className={styles.aboutHeroText}>
            Driven by inhuman hypergrowth, always-more-inaccessible technology,
            and a ridiculous lack of diversity, we believe the current
            entrepreneurial model is outdated. We designed a new model where we
            define success at the intersection of sustainability, profitability
            and impact.
          </Text>
        </Container>
      </Section>
    </Layout>
  )
}
